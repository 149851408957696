@import '../../../../styles/customMediaQueries.css';

.root {
  display: flex;
  align-items: center;
  background-color: var(--colorWhite);
  flex-wrap: wrap;
  border-radius: var(--borderRadiusMedium);
  padding: 10px 16px 16px;
  gap: 10px;
  position: relative;

  & > div:not(:last-of-type) {
    flex: 1 1 calc(50% - 20px);
  }

  @media (--viewportMedium) {
    padding: 0;
    flex-wrap: nowrap;
    gap: 0;
    height: 50px;
    border-radius: var(--borderRadiusLarge);

    & > div:not(:last-of-type) {
      flex: 1 1 100%;
    }
  }
}

.spacing {
  @media (--viewportMedium) {
    padding: 0 16px;

    &:not(:first-of-type):not(:last-of-type) {
      padding: 0 8px;
    }

    &:first-of-type {
      padding-right: 8px;
    }

    &:last-of-type {
      padding-left: 8px;
    }
  }

  @media (--viewportLarge) {
    padding: 0 20px;

    &:not(:first-of-type):not(:last-of-type) {
      padding: 0 10px;
    }

    &:first-of-type {
      padding-right: 10px;
    }

    &:last-of-type {
      padding-left: 10px;
    }
  }

  @media (--viewportLargeWithPaddings) {
    padding: 0 20px;
  }
}

.field {
  composes: spacing;
  color: var(--colorGrey700);
  border-radius: inherit;

  & input {
    border: 0;
    border-radius: inherit;
    padding-right: 0;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:focus,
    &:hover {
      border: 0;
      box-shadow: none;
      border-radius: inherit;
    }

    &::placeholder {
      text-transform: capitalize;
    }
  }
}

.eventDate {
  composes: field;

  & [class*='DateInput_inputRoot'] {
    & input {
      border: 0;
      border-radius: 0;
      background-position: 0 7px;
      background-size: 20px;

      &:focus,
      &:hover {
        border: 0;
        box-shadow: none;
        border-radius: var(--borderRadiusLarge);
      }

      @media (--viewportMedium) {
        border-radius: var(--borderRadiusLarge);
        background-position: 0 12px;
      }
    }
  }
}

.locationRoot {
  composes: spacing;

  border-radius: inherit;
}

.location {
  composes: field;

  padding: 0;
  position: static;

  &:first-of-type,
  &:last-of-type {
    padding: 0;
  }

  & input {
    height: unset;

    @media (--viewportMedium) {
      height: 50px;
    }
  }

  @media (--viewportMedium) {
    position: relative;
  }
}

.locationPredictions {
  right: 0;
  left: 0;
  text-align: left;

  @media (--viewportMedium) {
    min-width: 300px;
    left: 0;
    right: unset;
  }
}

.startTime {
  composes: field;

  color: var(--colorGrey300);
  display: flex;
  align-items: center;

  & label {
    color: var(--marketplaceColor);
    padding: 0;
    margin: 0;
  }

  & select {
    background: none;
    border: 0;
    border-radius: var(--borderRadiusLarge);
    box-shadow: none;
    cursor: pointer;

    text-transform: capitalize;
    padding: 6px 0 4px 12px;

    &:hover,
    &:focus {
      box-shadow: none;
    }

    @media (--viewportMedium) {
      padding: 7px 0 7px 16px;
    }
  }
}

.startTimeHasValue {
  color: var(--colorGrey700);
}

.bandType {
  composes: field;

  display: flex;
  align-items: center;
  text-align: left;

  & label {
    color: var(--marketplaceColor);
    padding: 0;
    margin: 0;

    & svg {
      width: 20px;
    }
  }

  & [class*='Dropdown_chipsContainer'] {
    display: none;
  }

  & ul {
    position: absolute;
    box-shadow: var(--boxShadowFilterButton);
    border-radius: var(--borderRadiusMedium);
    right: 0;
    left: 0;

    @media (--viewportMedium) {
      min-width: 300px;
      left: unset;
      right: unset;
    }
  }

  & input + button {
    display: none;
  }

  @media (--viewportMedium) {
    position: relative;
  }
}

.bandTypeSelected::placeholder {
  color: var(--colorGrey700);
}

.artistName {
  composes: field;

  display: flex;
  align-items: center;

  & label {
    color: var(--marketplaceColor);
    padding: 0;
    margin: 0;

    & svg {
      width: 20px;
    }
  }
}

.submit {
  width: 100%;
  padding-top: 24px;
  border-top: 1px solid var(--colorGrey100);

  & > button {
    height: 34px;
    border-radius: var(--borderRadiusLarge);
    min-height: unset;
    min-width: 100px;
    width: 100%;
  }

  @media (--viewportMedium) {
    width: unset;
    margin: 0 0 0 auto;
    padding: 0;
    border: 0;

    & > button {
      height: 50px;
    }
  }

  @media (--viewportLarge) {
    min-width: 142px;
  }
}
