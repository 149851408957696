@import '../../../styles/customMediaQueries.css';

.spacing {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0 32px;
  }
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--colorGrey50);
  border-top: 2px solid var(--colorGrey100);
}

.title {
  composes: spacing;

  text-transform: uppercase;
  text-align: center;
  max-width: var(--contentMaxWidthPages);
  color: var(--colorGrey800);
}

.marketpaceName {
  color: var(--marketplaceColor);
}

.desc {
  composes: spacing;

  text-align: center;

  max-width: var(--subContentMaxWidthPages);
  margin-bottom: 50px;
}

.columns {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 24px;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 34px 34px 30px;
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--boxShadowListingCard);

    & > h3 {
      color: var(--marketplaceColor);
      font-size: 18px;
      line-height: 26px;
      font-weight: var(--fontWeightMedium);
      margin: 24px 0;
      padding: 0;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100px;
      height: 100px;

      & img {
        width: 100px;
      }
    }

    & > ul {
      margin: 0;
      padding: 0 0 0 4px;
      list-style: disc;

      & > li {
        font-size: 14px;
        line-height: 22px;
        padding: 0;

        &::marker {
          color: var(--marketplaceSecondary);
        }

        &:not(:last-of-type) {
          margin-bottom: 8px;
        }
      }
    }
  }

  @media (--viewportMedium) {
    padding: 0 32px;
    grid-template-columns: repeat(3, calc((100% - 2 * 32px) / 3));
  }
}

.cta {
  margin-top: 40px;

  @media (--viewportMedium) {
    margin-top: 50px;
  }
}
