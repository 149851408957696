@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  background-image: url('./images/jazz-group.jpeg');
  background-position: center;
  background-size: cover;
  color: var(--colorWhite);
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;
  gap: 32px;
  padding: 0 24px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin: 70px auto;
    padding: 0 24px 0 32px;
  }
}

.text {
  z-index: 1;
  flex: 1 1 40%;
}

.title {
  text-transform: uppercase;
}

.desc {
  color: var(--colorLightTransparent);
}

.desktopCtas {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-top: 56px;
  }
}

.mobileCtas {
  display: block;
  z-index: 1;

  @media (--viewportMedium) {
    display: none;
  }
}

.ctas {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px 30px;
}

.primaryCta {
  composes: buttonPrimary from global;

  min-height: unset;
  padding: 12px 16px;
  flex: 1;
  min-width: 158px;
}

.secondaryCta {
  composes: buttonSecondary from global;

  color: var(--colorWhite);
  min-height: unset;
  padding: 10px 16px;
  flex: 1;
  min-width: 158px;
}

.images {
  position: relative;
  flex: 1 1 60%;
  z-index: 1;
  /* Padding for image to keep aspect ratio and take proper height on mobile */
  padding-top: 66.7%;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }

  @media (--viewportMedium) {
    padding-top: 0;
  }
}

.gradient {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--marketplaceGradientSecondary);
  opacity: 0.7;
}
