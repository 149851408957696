@import '../../styles/customMediaQueries.css';

.container {
  /* Carousel width breaks if we don't set this */
  min-width: 0;
}

.sections {
  margin: 0;
}

.section {
  display: flex;
  flex-direction: column;
  height: auto;

  background-color: var(--colorWhite);
  overflow: auto;
}

.sectionRoot {
  padding: 24px 0 50px;

  @media (--viewportMedium) {
    padding: 32px 0 60px;
  }

  @media (--viewportLarge) {
    padding: 32px 0 64px 0;
  }
}
