@import '../../../styles/customMediaQueries.css';

.spacing {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0 32px;
  }
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--colorGrey50);
  border-top: 2px solid var(--colorGrey100);
}

.title {
  composes: spacing;

  text-transform: uppercase;
  text-align: center;
  max-width: var(--subContentMaxWidthPages);
  color: var(--colorGrey800);
}

.desc {
  composes: spacing;

  text-align: center;

  max-width: var(--subContentMaxWidthPages);
  margin-bottom: 50px;
}

.categories {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  max-width: var(--contentMaxWidthPages);
  gap: 16px;
  padding: 0 24px;

  & > a {
    position: relative;
    text-decoration: none;
    min-width: 140px;
    width: 100%;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: var(--borderRadiusCard);
    }

    &:hover {
      text-decoration: none;

      & .categoryTitle {
        top: 0;
        background: rgba(0, 0, 0, 0.6);
        align-items: center;
      }
    }
  }

  @media (--viewportSmall) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportMedium) {
    gap: 32px;
    padding: 0 32px;
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.categoryTitle {
  position: absolute;
  bottom: 0;
  color: var(--colorWhite);
  left: 0;
  right: 0;
  text-align: center;
  border-radius: var(--borderRadiusCard);
  display: flex;
  justify-content: center;
  padding: 14px;
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--fontWeightSemiBold);
  transition: top 0.2s, background 0.2s;

  top: 0;
  background: rgba(0, 0, 0, 0.6);
  align-items: center;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
    top: 50%;
    background: linear-gradient(to top, var(--marketplaceColor), transparent);
    align-items: flex-end;
  }
}
