@import '../../../styles/customMediaQueries.css';

.spacing {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0 32px;
  }
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.title {
  composes: spacing;

  text-transform: uppercase;
  text-align: center;
  max-width: var(--subContentMaxWidthPages);
  color: var(--colorGrey800);
}

.desc {
  composes: spacing;

  text-align: center;

  max-width: var(--subContentMaxWidthPages);
  margin-bottom: 30px;

  @media (--viewportMedium) {
    margin-bottom: 50px;
  }
}

.regions {
  display: flex;
  width: 100%;
  max-width: var(--contentMaxWidthPages);
  gap: 16px;
  padding: 0 24px;

  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  & > a {
    position: relative;
    text-decoration: none;
    min-width: 340px;
    width: 100%;
    flex: 1 1 100%;
    transition: var(--transitionStyleButton);

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: var(--borderRadiusCard);
      background-color: var(--marketplaceColor);
    }

    &:hover {
      text-decoration: none;
      transform: scale(1.02);
    }
  }

  @media (--viewportMedium) {
    gap: 24px;
    padding: 0 32px;
  }

  @media (--viewportLarge) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    & > a {
      min-width: 220px;
    }
  }
}

.regionName {
  position: absolute;
  bottom: 0;
  color: var(--colorWhite);
  left: 0;
  right: 0;
  text-align: center;
  border-bottom-left-radius: var(--borderRadiusCard);
  border-bottom-right-radius: var(--borderRadiusCard);
  display: flex;
  justify-content: center;
  padding: 14px;
  font-size: 12px;
  line-height: 18px;
  font-weight: var(--fontWeightSemiBold);

  background: rgba(0, 0, 0, 0.7);
  align-items: center;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
    align-items: flex-end;
  }
}
