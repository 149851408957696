@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  background-image: url('./images/drumset.jpg');
  background-position: center;
  background-size: cover;
  color: var(--colorWhite);
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  width: 100%;
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;

  padding: 0 24px;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;

    margin: 100px auto;
    padding: 0 24px 0 32px;
  }
}

.text {
  z-index: 1;
  flex: 1 1 100%;

  @media (--viewportLarge) {
    flex: 0 1 40%;
  }
}

.boldText {
  font-weight: 700;
}

.title {
  text-transform: uppercase;
  z-index: 1;
  position: relative;
  text-align: center;
}

.desc {
  color: var(--colorLightTransparent);
}

.desktopCtas {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-top: 56px;
  }
}

.mobileCtas {
  display: block;
  z-index: 1;
  order: 3;
  flex: 0 1 80%;

  @media (--viewportLarge) {
    display: none;
  }
}

.ctas {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px 30px;
}

.primaryCta {
  composes: buttonPrimary from global;

  min-height: unset;
  padding: 12px 16px;
  flex: 1;
  min-width: 158px;
}

.secondaryCta {
  composes: buttonSecondary from global;

  color: var(--colorWhite);
  min-height: unset;
  padding: 10px 16px;
  flex: 1;
  min-width: 158px;
}

.images {
  position: relative;
  flex: 0 1 100%;
  max-width: 400px;
  z-index: 1;
  min-height: 200px;
  order: 2;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
  }

  & img {
    width: 100%;
    height: 90%;
    object-fit: scale-down;
    border-radius: 12px !important;
    object-fit: cover;
  }

  @media (--viewportXXSmall) {
    flex: 0 1 40%;
  }

  @media (--viewportMedium) {
    min-height: 250px;
  }

  @media (--viewportLarge) {
    padding-top: 0;
    max-width: unset;

    order: unset;
    flex: 0 1 25%;
  }
}

.gradient {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--marketplaceGradientSecondary);
  opacity: 0.7;
}
