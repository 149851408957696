@import '../../../styles/customMediaQueries.css';

@keyframes slideFromTop {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: slideFromTop;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url('/public/static/images/mm-hero.jpeg');
  background-position: center;
  background-size: cover;
  position: relative;
  color: var(--colorWhite);
  text-align: center;
}

.heroContent {
  flex: 1 1 100%;
  margin: 0 24px 0 24px;
  max-width: var(--contentMaxWidthPages);

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 32px 0 32px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    width: 100%;
    padding: 0 32px 0 32px;
  }
}

.titleAndForm {
  position: relative;
  z-index: 2;
}

.heroMainTitle {
  color: var(--colorWhite);

  animation-delay: 0.5s;
  margin: 40px auto 24px;
  text-align: center;
  text-transform: uppercase;
  max-width: 820px;

  visibility: hidden;

  @media (--viewportMedium) {
    margin-bottom: 40px;
    margin-top: 80px;
  }

  @media (--viewportLarge) {
    margin: 186px auto 50px;
  }
}

.searchForm {
  transition: all 0.4s;
  animation-delay: 0.5s;
  z-index: 1;

  visibility: hidden;
}

.heroSubTitle {
  color: var(--colorWhite);
  text-align: center;

  animation-delay: 0.65s;
  margin: 0 auto 30px;
  font-size: 14px;
  line-height: 22px;
  font-weight: var(--fontWeightRegular);
  max-width: var(--subContentMaxWidthPages);

  visibility: hidden;

  @media (--viewportMedium) {
    margin: 0 auto 76px;
    font-size: 18px;
    line-height: 28px;
  }
}

.citiesDesc {
  animation-delay: 0.5s;
  margin: 30px 0px;

  visibility: hidden;
}

.cities {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 42px;
  gap: 8px;
  animation-delay: 0.5s;
  z-index: 1;

  visibility: hidden;

  & > a {
    border-radius: var(--borderRadiusLarge);
    border: 1.5px solid var(--colorLightTransparent);
    color: var(--colorWhite);
    padding: 4px 12px;
    font-size: 12px;
    line-height: 18px;
  }

  @media (--viewportMedium) {
    gap: 16px;

    margin-bottom: 70px;

    & > a {
      font-size: 14px;
      line-height: 22px;
      flex-basis: 126px;
    }
  }
}

.gradient {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--marketplaceGradient);
  opacity: 0.7;
}

.dot {
  color: var(--marketplaceColor);
}
