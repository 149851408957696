@import '../../../styles/customMediaQueries.css';

.spacing {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0 32px;
  }
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.title {
  composes: spacing;

  text-transform: uppercase;
  text-align: center;
  max-width: var(--contentMaxWidthPages);
  color: var(--colorGrey800);
}

.desc {
  composes: spacing;

  text-align: center;

  max-width: var(--subContentMaxWidthPages);
  margin-bottom: 50px;
}

.cta {
  margin-top: 40px;

  @media (--viewportMedium) {
    margin-top: 50px;
  }
}

.error {
  color: var(--colorFail);
}
